import React from "react";
import MWifi from "./MWifi";
import MView from "./MView";
import { Spinner } from "reactstrap";
import Config from "./Config";
import FacebookButton from "./container/FacebookButton";
import InstagramButton from "./container/InstagramButton";
import ClubMemberButton from "./container/ClubMemberButton";
import WifiButton from "./container/WifiButton";
import TopTenButton from "./container/TopTenButton";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default class MHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWifiOpen: false,
    };
  }

  onWifiClose() {
    this.setState({ isWifiOpen: false });
  }
  onWifiClick() {
    this.setState({ isWifiOpen: true });
  }
  onClubMemberClick() {
    window.gEvent("open", "clubmember");
    window.location.href =
      Config.apiEndPoint +
      "/membersclub/?id=" +
      this.props.LocationID +
      "&locale=" +
      this.props.lng;
  }
  onFacebookClick() {
    window.open(this.props.data.Facebook, "fb");
  }
  onInstagramClick() {
    window.open(this.props.data.Instagram, "insta");
  }
  onFlagClick(lng) {
    console.log("onFlagClick", lng);
    if (this.props.onFlagClick != null) {
      this.props.onFlagClick(lng);
    }
  }

  onShowTopTenPressed() {
    console.log("onShowTopTenPressed");
    var url = Config.apiEndPoint + "/ws/Menu.asmx/GetTopTenItems";
    var p = {
      LocationID: this.props.LocationID,
      TakeOutMenu: this.props.isTakeout,
      RegularMenu:
        !this.props.isTakeout && !this.props.isDeliveryMenu ? true : false,
      DeliveryMenu: this.props.isDelivery,
    };
    console.log("params: ", p);
    this.props.showSpinner();
    window.post(this, url, p, (data) => {
      console.log("Response", data);
      if (data.ok) {
        if (data.TopTenItems.length < 1) {
          alert("Items no evaluated yet");
          return;
        }
        this.props.saveTopTenItems(data.TopTenItems);
      }
    });
  }

  onSwitchMenu() {
    console.log("onSwitchMenu");
    let url = document.location.href;
    console.log("The url is", url);

    if (url.includes("takeout")) {
      url = url.replace("takeout", "delivery");
    } else if (url.includes("delivery")) {
      url = url.replace("delivery", "takeout");
    }
    // if (url.includes("&in=true")){
    //   url = url.replace("&in=true","");
    // }
    //var win = window.open(url, '_blank');
    var win = window.open(url, "_self");
    win.focus();
  }

  render() {
    // console.log("this.props.isDelivery", this.props.isDelivery);
    // console.log("this.props.isTakeout", this.props.isTakeout);
    // console.log("this.props.MenuSwitchEnabled", this.props.MenuSwitchEnabled);

    let switchText = "";

    if (this.props.isTakeout) {
      switchText = this.props.GoDeliveryMenu;
    }
    if (this.props.isDelivery) {
      switchText = this.props.GoTakeOutMenu;
    }

    let topIcons = 225;
    if (!this.props.menuMembersClubEnabled) {
      topIcons = 205;
    }
    return (
      <div style={{ position: "relative", height: 260 }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 152,
            overflow: "hidden",
            backgroundImage: `url("${
              Config.apiEndPoint.replace("menualdev", "menual") +
              "/firstpageimages/" +
              this.props.LocationID +
              ".jpg"
            }")`,
            backgroundSize: "cover",
          }}
        ></div>
        <svg className="MHeader_bg" viewBox="0 0 418 238">
          <linearGradient
            id="header_gd"
            spreadMethod="pad"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="0.805"
          >
            <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#000" stopOpacity="0"></stop>
          </linearGradient>
          <path
            fill="url(#header_gd)"
            d="M 0 0 L 418 0 L 418 185 L 418 238 L 0 238 L 0 0 Z"
          ></path>
        </svg>
        <div style={{ position: "absolute", top: 18, width: "100%" }}>
          <span className="MHeader_text">{this.props.data.WelcomeText}</span>
          <br />
          <span
            className="MHeader_LocationName"
            style={{ color: this.props.ColorText }}
          >
            {this.props.data.Name}
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: 152,
            width: "100%",
            backgroundColor: this.props.color,
            height: 8,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: 128,
            width: "100%",
            top: 89,
          }}
        >
          <table
            border="0"
            style={{ width: "100%", borderWidth: 1, borderColor: "black" }}
          >
            <tbody>
              <tr>
                <td></td>
                <td style={{ width: 128, position: "relative" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 128,
                      height: 128,
                      borderRadius: 64,
                      position: "absolute",
                      boxShadow: "0px 0px 3px #CCC",
                    }}
                  >
                    {this.props.LocationID ? (
                      <img
                        style={{
                          padding: 10,
                          width: 128,
                          height: 128,
                          borderRadius: 64,
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        alt="restaurant logo"
                        src={
                          Config.apiEndPoint +
                          "/logos/" +
                          this.props.LocationID +
                          ".jpg"
                        }
                      ></img>
                    ) : (
                      <Skeleton circle={true} height={128} width={128} />
                    )}
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <MView visible={this.props.showControls}>
          {this.props.menuWiFiEnabled && (
            <WifiButton
              invisible={this.props.isTakeout}
              text={this.props.data.WifiText}
              onClick={this.onWifiClick.bind(this)}
              isTakeout={this.props.isTakeout}
              topTenEnabled={this.props.topTenEnabled}
            />
          )}

          {!this.props.isTakeout && this.props.topTenEnabled && (
            <TopTenButton
              invisible={!this.props.topTenEnabled}
              onClick={this.onShowTopTenPressed.bind(this)}
            />
          )}

          {this.props.MenuSwitchEnabled && (
            <div
              ref="switchMenu"
              onClick={this.onSwitchMenu.bind(this)}
              // onMouseDown={this.handleEvent.bind(this, 'Reviews')}
              // onMouseUp={this.handleEvent.bind(this, 'Reviews')}
              className="Bold"
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: 14,
                textAlign: "center",
                ...{
                  width: "40%",
                  borderRadius: 18,
                  margin: 0,
                  marginTop: 10,
                  marginBottom: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: "black",
                  position: "absolute",
                  left: "30%",
                },
              }}
            >
              {switchText}
            </div>
          )}

          {this.props.menuMembersClubEnabled && (
            <ClubMemberButton
              onClick={this.onClubMemberClick.bind(this)}
              text={this.props.data.JoinText}
            />
          )}

          {this.props.menuSocialMediaEnabled && (
            <FacebookButton
              onClick={this.onFacebookClick.bind(this)}
              top={topIcons}
            />
          )}

          {this.props.menuSocialMediaEnabled && (
            <InstagramButton
              onClick={this.onInstagramClick.bind(this)}
              top={topIcons}
            />
          )}

          <MView visible={false}>
            <div
              style={{
                position: "absolute",
                top: 195,
                left: 89,
                width: 23,
                height: 23,
                fontSize: 12,
              }}
            >
              <img
                src={require("./images/icon-email.svg")}
                style={{ width: 23, height: 23 }}
              ></img>
            </div>
          </MView>

          <MWifi
            color={this.props.color}
            data={this.props.data.WiFi}
            onClose={this.onWifiClose.bind(this)}
            visible={this.state.isWifiOpen}
            lang={this.props.lng}
            direction={this.props.direction}
          ></MWifi>
        </MView>

        {this.props.flagsSelection && (
          <div style={{ position: "absolute", top: 5, right: 5 }}>
            <MView
              visible={this.props.isLngLoading}
              style={{ padding: 5, paddingTop: 4, position: "absolute" }}
            >
              <Spinner
                size="sm"
                color="dark"
                style={{ width: "1.2rem", height: "1.2rem" }}
              />
            </MView>

            <MView visible={this.props.lng == "he"}>
              <img
                src={require("./images/flag-us.svg")}
                style={{ width: 30, height: 30 }}
                onClick={this.onFlagClick.bind(this, "en")}
              ></img>
            </MView>

            <MView visible={this.props.lng == "en"}>
              <img
                src={require("./images/flag-il.svg")}
                style={{ width: 30, height: 30 }}
                onClick={this.onFlagClick.bind(this, "he")}
              ></img>
            </MView>
          </div>
        )}
      </div>
    );
  }
}
