import React from 'react';
import MView from './MView';


export default class MFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onClick(){
        window.open('https://www.menual.net','menual');
    }
    onCategoryClick(){
        console.log("Category");
        this.props.categoryClick();
    }
    
    render() {
        var showCategory = (this.props.category != null) ? 'visible' : '';
        console.log("MFooter", showCategory);
        
        return (

            <div className="floatFooter" 
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: this.props.width, height: 45, backgroundColor: '#000000', marginTop:90 }} >
                
                <MView visible={showCategory} >
                    <img
                        src={require('./images/list_white.svg')}
                        onClick={this.onCategoryClick.bind(this)}  
                        style={{ position: "absolute", left: 7, top: 10, width: 50, height: 20 }}></img>
                </MView>

                <img src={require('./images/logo-menual.svg')} onClick={this.onClick.bind(this)} style={{ position: "absolute", left: 137, top: 7, width: 100, height: 20 }}></img>
                <div onClick={this.onClick.bind(this)} style={{ color: 'white', fontSize: 6, position: "absolute", left: 189, top: 2 }}>
                    powered by
                </div>
               
            </div>
        );
    }
}