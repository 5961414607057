import React from "react";

const InstagramButton = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: props.top,
        left: 34,
        width: 23,
        height: 23,
        fontSize: 12,
      }}
      onClick={props.onClick}
    >
      <img
        alt="instagram button"
        src={require("../images/icon-instagram.svg")}
        style={{ width: 23, height: 23 }}
      ></img>
    </div>
  );
};

export default InstagramButton;
