import React, { useEffect } from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

const CustomToast = (props) => {
  useEffect(() => {
    let timeout = setTimeout(() => {
      props.isOpen && props.onTimeout();
    }, props.timeout);

    return () => clearTimeout(timeout);
  }, [props.isOpen]);

  if (!props.isOpen) {
    return null;
  }
  return (
    <div className="p-3 bg-success my-2 rounded">
      <Toast>
        <ToastHeader>{props.title}</ToastHeader>
        <ToastBody>{props.text}</ToastBody>
      </Toast>
    </div>
  );
};

CustomToast.defaultProps = {
  type: "success",
  title: "Menual",
  isOpen: false,
  timeout: 3000,
};

export default CustomToast;
