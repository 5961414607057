import React from 'react'


const TopTenButton = (props) => {
  if (props.invisible) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 218,
        left: 287,
        width: 76,
        height: 30,
        fontSize: 12,
      }}
      // onClick={props.onClick}
    >
      <button
        onClick={props.onClick.bind(this)} 
        style={{
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: 'black',
          color: 'white',
          borderRadius: 32,
          border: 0,
          fontSize: 12,
          fontWeight: 'bold',
          margin: 5,
        }}>
        טופ 10
      </button>
    </div>
  );
};

export default TopTenButton