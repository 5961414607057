import React from 'react';
import { Container, Row, Col, Collapse, Label} from 'reactstrap';
import MProduct from './MProduct';
import MView from './MView';

export default class MSearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen:true};
    }
    onClick(){
        if (this.props.onClick){
            this.props.onClick();
        }
    }
    render() {
        let {data} = this.props
        if (this.props.visible == false) {
            return null;
        }
        var MenuItems = [];
        if (this.props.data != null) {
            MenuItems = this.props.data;
        }
        return (
            <Collapse isOpen={this.state.isOpen}>
                <MView visible={this.state.isOpen}>                    
                    {MenuItems.map((item) =>
                        <MProduct 
                            key={item.ID} 
                            data={item} 
                            addBtnText={this.props.addBtnText} 
                            direction={this.props.direction} 
                            onAddingProduct={this.props.setAddingProductLoading}
                            getDataFromServer={this.props.getDataFromServer}
                            addEnabled={this.props.addEnabled}
                        />
                    )}
                    {data.length == 0 && this.props.searched && !this.props.loading &&
                        <Label style={{fontSize: 24, marginTop:24, marginBottom: 0}}>{this.props.noResultsText}</Label>
                    }
                </MView>
            </Collapse>
        );
    }
}