import React, {useState} from 'react';
import { Container, Row, Col, Collapse, Label} from 'reactstrap';
import MProduct from './MProduct';
import MView from './MView';

const MTopTenResults = (props) => {
  console.log('MTopTenResults items: ', props.items)
  const [listOpened, setListOpened] = useState(true)

  const style = { 
    textAlign: props.direction, 
    paddingTop: 6, 
    height: 45, 
    backgroundColor: '#0000FF', 
    position: "relative", 
    fontStyle: 'bold', 
    fontSize: 27, 
    color: 'white' 
  }
  const handleListOpened = listOpened => {
    let isOpened = !listOpened
    setListOpened(isOpened)
  }
  return (
    <Collapse style={{marginTop: 20, marginBottom: 50}} isOpen={true}>
      <div onClick={() => handleListOpened(listOpened)} className='Bold' style={style}>
      טופ 10
      </div>          
      <MView visible={listOpened}>       
        {props.items.length > 0 ? 
          props.items.map((item) =>
            <MProduct 
              key={item.ID} 
              data={item} 
              addBtnText={props.addBtnText} 
              direction={props.direction} 
              onAddingProduct={props.setAddingProductLoading}
              getDataFromServer={props.getDataFromServer}
              addEnabled={props.addEnabled}
            />
          )
        :
        props.items.length == 0 && props.itemsShown && !props.loading &&
          <Label 
            style={{
              fontSize: 24, 
              marginTop:24, 
              marginBottom: 0
            }}
          >
            לא נמצאו תוצאות
          </Label>
        }
      </MView>
    </Collapse>
  )
};

export default MTopTenResults;
