import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import MView from './MView';
import Config from './Config';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default class MProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewBtnStyle: { height: 20, borderRadius: 18, margin: 10, marginTop: 3, paddingTop: 3, paddingLeft: 13, paddingRight: 13, backgroundColor: 'black' },
            reviewBtnPressedStyle: { height: 20, borderRadius: 18, margin: 10, marginTop: 3, paddingTop: 3, paddingLeft: 13, paddingRight: 13, backgroundColor: 'gray' },
            btnAnimatedPressedSize: { width: 87, height: 37, borderRadius: 18, marginLeft: 32, marginTop: -16, paddingTop: 6, backgroundColor: 'gray' },
            btnAnimatedSize: { width: 87, height: 37, borderRadius: 18, marginLeft: 32, marginTop: -16, paddingTop: 6, backgroundColor: 'black' },
            btnAnimatedAddSize: { width: 87, height: 37, borderRadius: 18, marginLeft: 32, marginTop: -16, paddingTop: 6, backgroundColor: 'black' },
            btnAnimatedGetReviews: { height: 20, borderRadius: 18, margin: 10, marginTop: 3, paddingTop: 3, paddingLeft: 13, paddingRight: 13, backgroundColor: 'black' },
            btnAnimatedAddReview: { height: 20, borderRadius: 18, margin: 5, marginTop: 3, paddingTop: 3, paddingLeft: 13, paddingRight: 13, backgroundColor: 'black' }
        };
        this.itemLiked = false
    }

    onClick() {

        window.gEvent('MProduct Add click', this.props.data);
        window.gEvent('UserID', window.UserID);

        if (this.props.onAddingProduct) {
            let addingItem = Object.assign({ AddID: uuidv4() }, this.props.data)
            this.props.onAddingProduct(addingItem);
        }

        if (this.props.onClick) {
            this.props.onClick();
        }


    }


    onError(e) {
        //console.log('onError', e.target.src);
        e.target.src = 'https://menual.azurewebsites.net/images/missing_image.jpg';
    }

    btnPressed(type, state, btnStyle, btnPressedStyle) {
        if (type === 'mousedown') {
            this.setState({
                //btnAnimatedSize: { width: 97, height: 41, borderRadius: 21, marginLeft: 14, marginTop: -15, paddingTop: 12, backgroundColor: 'gray' }
                [state]: btnPressedStyle
            });
        } else if (type === 'mouseup') {
            setTimeout(() => {
                this.setState({
                    [state]: btnStyle
                });
            }, 100);
        }
    }

    handleEvent(btnType, e) {
        console.log("handleEvent", e.type);
        if (btnType === 'Reviews') {
            this.btnPressed(e.type, 'btnAnimatedGetReviews', this.state.reviewBtnStyle, this.state.reviewBtnPressedStyle)
            return
        }
        if (btnType === 'AddReview') {
            this.btnPressed(e.type, 'btnAnimatedAddReview', this.state.reviewBtnStyle, this.state.reviewBtnPressedStyle)
            return
        }
        this.btnPressed(e.type, 'btnAnimatedSize', this.state.btnAnimatedAddSize, this.state.btnAnimatedPressedSize)
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }
        // console.log("Render MProduct");
        var item = this.props.data;

        let align = (this.props.direction === "rtl" ? "right" : "left");
        let otherAlign = (this.props.direction === "rtl" ? "left" : "right");
        let alignment = this.props.direction === "rtl" ? "flex-end" : "flex-start"
        let direction = this.props.direction === "rtl" ? "row-reverse" : "row"

        let imageUrl = null;
        if (item.Image && item.Image.length > 50) {
            imageUrl = item.Image;
            imageUrl = imageUrl.replace("https://menual.azurewebsites.net", Config.apiEndPoint);
            //console.log("La mera img url", imageUrl);
        }

        this.itemLiked = false
        if (this.props.itemsLikedArray && this.props.itemsLikedArray.length > 0) {
            for (let i = 0; i < this.props.itemsLikedArray.length; i++) {
                if (this.props.itemsLikedArray[i].ID === item.ID) {
                    this.itemLiked = true
                }
            }
        }

        return (
            <div style={{ marginBottom: 90 }} dir={this.props.direction}>
                <div className="Medium" style={{ width: '100%', backgroundColor: '#F3F3F3' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ padding: 10, paddingBottom: 0, textAlign: align }}>
                            <span>{item.Name}
                            </span>
                            {item.Vegan || item.Vegiterian || item.Dairy || item.Parve  ? <br /> : null}
                            <span>
                                {item.Vegan ? <img src="/imgs/VEGAN.png" width="20"/> : null}
                                {item.Vegiterian ? <img src="/imgs/VEGATERIAN.png" width="20"/> : null}
                                {item.Dairy ? <img src="/imgs/GLUTEN-FREE_ICON.png" width="20"/> : null}
                                {item.Parve ? <img src="/imgs/HOT_ICON.png" width="20"/> : null}
                            </span>
                            <span style={{ height: 1, width: '200', marginTop: 12, backgroundColor: '#D0D0D0' }}>

                            </span>
                            <span style={{ fontWeight: 'bold', float: otherAlign }}>{item.Price}</span>
                        </div>
                        <div style={{ width: '100%', height: 25, display: 'flex', marginTop: 5, marginBottom: 8 }}>
                            <div style={{ width: '50%', height: 25, display: 'flex', justifyContent: otherAlign }}>
                                {this.props.menuReviewsEnabled &&
                                    <>
                                        <div
                                            ref="getItemReviews"
                                            onClick={this.props.getItemReviews.bind(this, item)}
                                            onMouseDown={this.handleEvent.bind(this, 'Reviews')}
                                            onMouseUp={this.handleEvent.bind(this, 'Reviews')}
                                            className="Bold"
                                            style={{ color: 'white', cursor: 'pointer', fontSize: 12, textAlign: 'center', ...this.state.btnAnimatedGetReviews }}>
                                            {`Reviews (${item.Reviews})`}
                                        </div>
                                        {/* <div 
                                            ref="onOpenReviewModal"
                                            onClick={this.props.onOpenReviewModal.bind(this, item, 'AddReview')}
                                            onMouseDown={this.handleEvent.bind(this, 'AddReview')}
                                            onMouseUp={this.handleEvent.bind(this, 'AddReview')}
                                            className="Bold"
                                            style={{ color: 'white', cursor:'pointer', fontSize: 12, textAlign: 'center', ...this.state.btnAnimatedAddReview }}>
                                            {'Add Review'}
                                        </div> */}
                                    </>
                                }
                            </div>
                            <div style={{ width: '50%', height: 25, textAlign: otherAlign, paddingRight: '5%', paddingLeft: '5%' }}>
                                {this.props.menuLikesEnabled &&
                                    <>
                                        <img
                                            src={this.itemLiked ? require("./images/heart_on.svg") : require("./images/heart_off.svg")}
                                            onClick={this.props.toggleItemLiked.bind(this, item)}
                                            style={{ width: 15, height: 24, paddingBottom: 2 }}
                                            alt="Like icon"
                                        />
                                        <span style={{ marginRight: 10, marginLeft: 10 }}>{item.LikesCount}</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div style={{ width: '100%', padding: 13, paddingTop: 3, fontSize: 15, textAlign: align }}>
                            {item.Description}
                        </div>
                        {
                            (imageUrl) &&
                            <div style={{ width: 306, height: 228, backgroundColor: 'white', borderRadius: 10, display: 'inline-block' }}>
                                <img style={{ width: 300, height: 224, objectFit: 'contain', objectPosition: 'center' }} src={imageUrl} onError={this.onError.bind(this)}></img>
                            </div>
                        }

                        <div style={{ height: 30 }}></div>
                    </div>
                </div>
                {
                    (this.props.addEnabled) &&
                    <MView visible={true}>
                        <div ref="addProductButton"
                            onClick={this.onClick.bind(this)}
                            onMouseDown={this.handleEvent.bind(this, 'Add')}
                            onMouseUp={this.handleEvent.bind(this, 'Add')}
                            className="Bold"
                            style={{ color: 'white', cursor: 'pointer', fontSize: 20, float: otherAlign, textAlign: 'center', ...this.state.btnAnimatedSize }}>
                            {this.props.addBtnText}
                        </div>
                    </MView>
                }

            </div>
        );
    }
}