import axios from "axios";
console.log("process.env.REACT_APP_DOMAIN", process.env.REACT_APP_DOMAIN);
const instace = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN
    ? process.env.REACT_APP_DOMAIN.trim()
    : document.location.hostname == "menu.menual.net"
    ? "https://menual.azurewebsites.net"
    : "https://menualdev.azurewebsites.net",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instace.serializeJSON = (data) => {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};
export default instace;
