import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class MView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onClick(){
        if (this.props.onClick){
            this.props.onClick();
        }
    }
    render() {
        if (this.props.visible == false) {
            return null;
        }
        var style = this.props.style;
        if (style != null)
        {
            if(style.display == null){
                //style.display = 'flex';
            } 
            //justifyContent: 'center', alignItems: 'center'
            //console.log('style',style);
        }
        return (
            <div style={style} onClick={this.onClick.bind(this)}>
                {this.props.children}
            </div>
        );
    }
}