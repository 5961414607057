import React from 'react'


const WifiButton = (props) => {
  if (props.invisible) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        // top: 171, //This is when TopTen Button will be added
        top: !props.isTakeout&&props.topTenEnabled ? 171 : 191,
        left: 287,
        width: 76,
        height: 30,
        fontSize: 12,
      }}
      onClick={props.onClick}
    >
      <img
        src={require("../images/icon-wifi.svg")}
        style={{ width: 52, height: 30 }}
      ></img>
      <br></br>
      {props.text}
    </div>
  );
};

export default WifiButton