import React from "react";
import { Form, FormGroup, Button, Input } from "reactstrap";
import MView from "./MView";

export default class MWifi extends React.Component {
  constructor(props) {
    super(props);
    console.log("constructor");
    this.state = { isOpen: true, phone: null };
    this.visible = this.props.visible;
  }
  onClose() {
    if (this.props.onClose != null) {
      this.props.onClose();
    }
  }
  onChange(e) {
    //console.log('onChange',e.target.value);

    this.setState({
      phone: e.target.value,
    });
  }
  onClick() {
    console.log("onClick");
  }

  onSendClick() {
    var url = "https://menual.azurewebsites.net/ws/Menu.asmx/GetWifiLoginInfo";
    var p = {
      LocationID: this.props.data.LocationID,
      Phone: this.state.phone,
    };

    console.log("onSendClick", url, p);

    fetch(url, {
      method: "POST",
      // whatever data you want to post with a key-value pair
      body: window.serializeJSON(p),

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("fetch response", data);
        this.onClose();
        //alert('Please check your SMS');
      });
  }
  render() {
    console.log("invisible", this.props.invisible);

    if (this.props.visible == false) {
      return null;
    }

    return (
      <div id="myModal" className="mmodal" style={{ display: "block" }}>
        <div
          className="mmodal-wifi-content"
          style={{
            borderRadius: 20,
            height: 320,
            width: 350,
            position: "relative",
          }}
        >
          <div
            onClick={this.onClose.bind(this)}
            className="close"
            style={{
              marginRight: 17,
              marginTop: 12,
              color: "#C7C7C7",
              fontSize: 20,
            }}
          >
            x
          </div>
          <div className="Bold" style={{ fontSize: 28, margin: 20 }}>
            {this.props.data.WifiTitle}
          </div>
          <div
            style={{
              fontSize: 14,
              color: "#101010",
              width: "100%",
              padding: 5,
            }}
          >
            {this.props.data.PhoneInputText}
          </div>
          <MView visible={true}>
            <Form>
              <FormGroup style={{ padding: 10 }}>
                <Input
                  onChange={this.onChange.bind(this)}
                  type="tel"
                  placeholder={this.props.data.PhoneInputPlaceHolder}
                  dir={this.props.direction}
                />
              </FormGroup>
              <Button
                style={{
                  backgroundColor: this.props.color,
                  borderStyle: "none",
                }}
                onClick={this.onSendClick.bind(this)}
              >
                {this.props.data.SendText}
              </Button>
            </Form>
          </MView>
        </div>
      </div>
    );
  }
}
