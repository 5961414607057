import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Locale from "../lang";

const CustomSelect = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [Dinners, setDinners] = useState(1);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    props.onChange(props.name, Dinners);
    return () => {};
  }, [Dinners]);
  const Items = [];
  for (let i = 1; i <= 15; i++) {
    Items.push(
      <DropdownItem
        key={i}
        onClick={() => {
          setDinners(i);
          props.onChange(props.name, i);
        }}
      >
        {i}
      </DropdownItem>
    );
  }

  if (props.invisible) {
    return null;
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
        textAlign: "left",
        paddingLeft: 18,
        marginTop: 10,
      }}
    >
      <h6>{Locale.getLocale("number-dinner")}</h6>
      <DropdownToggle caret>{Dinners}</DropdownToggle>

      <DropdownMenu>
        <DropdownItem>{Locale.getLocale("number-dinner")}</DropdownItem>
        {Items}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomSelect;
