import React from 'react';

export default class MButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { color: this.props.color};
    }
    onClick(){
        if(this.props.onClick != null){
            this.props.onClick();
        }
    }
    onMouseOver(e){
        //console.log('onMouseOver',e.target);
        this.setState({color:'black'});
    }
    onMouseLeave(e){
        //console.log('onMouseLeave', e.target);
        this.setState({ color: this.props.color });
    }
    render() {
        if (this.props.visible === false) {
            return null;
        }
        return (
            <div style={{ display: 'inline-block',margin:25}}>
                <button onMouseLeave={this.onMouseLeave.bind(this)} onMouseOver={this.onMouseOver.bind(this)} className="mbutton" style={{ height: 59, width: 288,backgroundColor:this.state.color,borderStyle:'none' }} onClick={this.onClick.bind(this)}>{this.props.text}</button>
            </div>
        );
    }
}