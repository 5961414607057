import React, { useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Row, Col, InputGroup, Input } from "reactstrap";

const MyContainer = ({ className, children }) => {
  return (
    <CalendarContainer className={className}>
      <div style={{ display: 'flex'  }}>
        {children}
      </div>
    </CalendarContainer>
  );
}

const DatePickerObj = ({
  name,
  onChange,
  HoursOfOperation,
  MilitaryTimeFormat,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const includeDates = [];
  const includeHours = [];
  const [OnDateChanged, setOnDateChanged] = useState(false);
  HoursOfOperation.forEach((hour) => {
    if (moment().day() <= hour.Day) {
      includeDates.push(moment().day(hour.Day).toDate());
    }
    includeDates.push(moment().day(hour.Day).add(1, "week").toDate());
  });

  HoursOfOperation.forEach((hour) => {
    if (moment(startDate).day() == hour.Day) {
      let StartMinute = hour.StartMinute;
      for (let h = hour.StartHour; h <= hour.EndHour; h++) {
        let EndMinute = 60;
        if (h + 1 > hour.EndHour) {
          EndMinute = hour.EndMinute;
        }
        for (let m = StartMinute; m <= EndMinute; m++) {
          includeHours.push(
            moment().day(hour.Day).hours(h).minutes(m).toDate()
          );
        }
        StartMinute = 0;
      }
    }
  });

  return (
    <React.Fragment>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          console.log("onChange=======>>>>>>>>");
          setOnDateChanged(true);
          onChange &&
            onChange(name, moment(startDate).format("YYYY-MM-DD kk:mm"));
        }}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        className={OnDateChanged ? "" : "incorrect"}
        timeCaption="time"
        includeDates={includeDates}
        includeTimes={includeHours}
        dateFormat={
          MilitaryTimeFormat ? "dd/MM/yyyy kk:mm" : " MM/dd/yyyy h:mm aa"
        }
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '0rem 0rem'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        calendarContainer={MyContainer}
      />
      <style>{`
                .incorrect {
                    border : 3px solid red;
                }
            `}</style>
    </React.Fragment>
  );
};

export default DatePickerObj;
