import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, FormGroup, Label } from "reactstrap";
import CreditCardInput from "react-credit-card-input";
import Locale from "../lang";
import { InputGroup, Input } from "reactstrap";
import Axios from "axios";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Config from "../Config";
import lang from "../lang";
import numeral from "numeral";
import CustomToast from "./CustomToast";
import Data from "../Data";
import CustomSelect from "./CustomSelect";
import DatePicker from "./DatePicker";

const FullWidthInput = (props) => {
  if (props.invisible) {
    return null;
  }

  return (
    <Col xs="12">
      <InputGroup
        style={{
          width: "90%",
          marginTop: 10,
          marginLeft: "5%",
          ...props.style,
        }}
      >
        <Input
          type={props.type || "text"}
          name={props.name}
          onChange={(e) => props.onChange(e.target.name, e.target.value)}
          style={{
            backgroundColor: "transparent",
          }}
          placeholder={props.placeholder}
        />
      </InputGroup>
    </Col>
  );
};
export default class TakeoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotCode: false,
      codeSent: false,
      CaptureCreditCardInReservation: false,
      ccInfo: {
        name: "",
        number: "",
        expiry: "",
        ccv: "",
      },
      Tip: 0,
      isScheduled: false,
      creditCardInfoValid: false,
      creditCardRules: {},
      creditCardNeeded: false,
      creditCardAmountChargePP: 0,
      cash: false,
    };
  }

  onInputChange = (name, value) => {
    console.log("onInputChange", name, value);
    this.setState({
      [name]: value,
    });
  };

  isCCValid = () => {
    let isValid =
      this.state.ccInfo.number &&
      this.state.ccInfo.expiry &&
      this.state.ccInfo.ccv
        ? true
        : false;
    // console.log("isCCValid", isValid);
    return isValid;
  };

  serializeJSON = (data) => {
    return Object.keys(data)
      .map(function (keyName) {
        return (
          encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
        );
      })
      .join("&");
  };

  getVerificationCode() {
    this.setState({
      fetching: true,
    });

    Axios.post(
      Config.apiEndPoint + "/ws/Menu.asmx/GetVerifyTakeoutCode",
      this.serializeJSON({
        Phone: this.state.phone,
        TableID: this.props.TableID,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then(({ data }) => {
        console.log("GetVerificationCode", data);
        if (data.ok) {
          this.setState({
            gotCode: true,
            codeSent: true,
          });
        } else alert(lang.getLocale("verification-error"));
      })
      .catch(() => {
        this.setState({
          gotCode: false,
        });
      })
      .then(() => {
        this.setState({
          fetching: false,
        });
      });
  }

  createOrder = () => {
    let takeoutItems = Data.getTakeoutItems();
    let filteredTakeoutItems = Object.assign({}, takeoutItems);
    console.log("filteredTakeoutItems", filteredTakeoutItems);
    let parentOptions = {};
    let itemsNotes = {};
    if (this.orderSent) {
      return;
    }

    this.orderSent = true;
    
    Object.keys(takeoutItems).forEach((AddID) => {
      if (takeoutItems[AddID].parentOptions) {
        parentOptions[AddID] = Object.assign(
          {},
          takeoutItems[AddID].parentOptions
        );
      }
      if (takeoutItems[AddID].notes)
        itemsNotes[AddID] = takeoutItems[AddID].notes;
      Object.keys(takeoutItems[AddID]).forEach((toppingID) => {
        if (
          // toppingID != "parentOptions" &&
          toppingID != "notes" &&
          !filteredTakeoutItems[AddID][toppingID].ID
        ) {
          console.log("going to delete", JSON.parse(JSON.stringify(filteredTakeoutItems[AddID][toppingID])))
          delete filteredTakeoutItems[AddID][toppingID];
        }
      });
    });

    //Here we are going to format the item for the backend
    // We are adding the notes from the takeout items
    let formatedItems = this.props.takeoutList.map((item) => {
      let itemTemp = Object.assign({}, item);
      itemTemp.notes = itemsNotes[item.ID];
      delete itemTemp.Toppings;
      delete itemTemp.ParentOptions;
      return itemTemp;
    });

    console.log("formattedItems", formatedItems)

    
    Axios.post(
      Config.apiEndPoint + "/ws/Menu.asmx/CreateTakeoutOrder",
      this.serializeJSON({
        VerifyCode: this.state.verifyCode,
        TableID: this.props.TableID,
        Items: JSON.stringify(formatedItems),
        No: this.state.ccInfo.number,
        Expire: this.state.ccInfo.expiry,
        CCV: this.state.ccInfo.ccv,
        TotalOrder: parseFloat(this.props.TotalOrder),
        Name: this.state.Name,
        Notes: this.state.Notes,
        Phone: this.state.phone,
        Cash: this.state.cash,
        Tip: this.state.Tip,
        ListUserID: window.UserID,
        isDelivery: this.props.isDelivery,
        Address: this.state.Address,
        Dinners: this.state.Dinners,
        isScheduled: this.state.isScheduled,
        isCurbside: this.state.isCurbside,
        vehicleModel: this.state.vehicleModel,
        vehicleColor: this.state.vehicleColor,
        vehiclePlate: this.state.vehiclePlate,
        ScheduledDate: this.state.ScheduledDate,
        City: this.state.City,
        ZIP: this.state.ZIP,
        DeliveryInstructions: this.state.DeliveryInstructions,
        TakeoutToppings: JSON.stringify(filteredTakeoutItems),
        ParentOptions: JSON.stringify(parentOptions),
        Version : Data.getVersion()
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then(({ data }) => {
        console.log("createOrder", data);
        this.orderSent = false;
        if (data.ok) {
          alert(lang.getLocale("takeout-created"));
          this.state = {};
          this.setState(this.state);
          window.location.reload();
        } else alert(lang.getLocale("takeout-error"));
      })
      .catch(() => {
        this.orderSent = false;
        this.setState({
          //gotCode: T,
        });
      });
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const takeoutList = this.props.takeoutList || [];

    return (
      <div>
        <Modal
          autoFocus={false}
          isOpen={this.props.isOpen}
          toggle={this.props.onClose}
        >
          <ModalHeader toggle={this.props.onClose}>
            <div
              style={{
                position: "absolute",
                width: "90%",
                textAlign: "center",
              }}
            >
              <label
                style={{
                  fontSize: 26,
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                {Locale.getLocale("total-order")} {this.props.MoneySign}
                {numeral(
                  parseFloat(this.props.TotalOrder) + parseFloat(this.state.Tip)
                ).format("0,0.00")}
              </label>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 5,
                  padding: 5,
                  marginLeft: "10%",
                  marginRight: "10%",

                  alignSelf: "center",
                  marginBottom: 20,
                }}
              >
                <span
                  style={{
                    fontWeight: "800",
                    fontSize: 20,
                  }}
                >
                  {lang.getLocale("pickup-title")}
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                  }}
                >
                  {Data.get().EmployerAddress}
                </span>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: 16,
                    color: "#ffa500",
                  }}
                >
                  {Data.get().EmployerTelephone}
                </span>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col xs="12">
                  <span>
                    {this.props.isTakeout
                      ? this.props.TakeoutNotice
                      : this.props.DeliveryNotice}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ size: 12 }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PhoneInput
                    country={this.props.lang == "en" ? "us" : "il"}
                    inputStyle={{
                      alignSelf: "center",
                      //   marginLeft: "2%",
                      width: "100%",
                      direction: "ltr",
                    }}
                    containerStyle={{
                      direction: "ltr",
                    }}
                    value={this.state.phone}
                    onChange={(phone) => this.setState({ phone })}
                  />
                </Col>
                <Col
                  xs="6"
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <Button
                    disabled={this.state.fetching}
                    color="primary"
                    onClick={this.getVerificationCode.bind(this)}
                  >
                    {lang.getLocale("get-code")}
                  </Button>{" "}
                </Col>
                <Col xs={"12"}>
                  <CustomToast
                    text={lang.getLocale("verification-code-sent")}
                    isOpen={this.state.codeSent}
                    onTimeout={() => {
                      this.setState({
                        codeSent: false,
                      });
                    }}
                  />
                </Col>

                {(this.state.gotCode || this.props.debug) && (
                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FullWidthInput
                      invisible={!this.state.gotCode && !this.props.debug}
                      name="verifyCode"
                      style={{
                        width: "70%",
                        marginBottom: 10,
                        marginLeft: 0,
                      }}
                      onChange={this.onInputChange}
                      placeholder={lang.getLocale(
                        "verification-code-placeholder"
                      )}
                    />

                    {this.props.EnableScheduled && (
                      <FormGroup
                        tag="fieldset"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              onChange={(e) =>
                                this.onInputChange(e.target.name, false)
                              }
                              name="isScheduled"
                              checked={!this.state.isScheduled}
                            />{" "}
                            {lang.getLocale("is-scheduled-now")}
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              onChange={(e) =>
                                this.onInputChange(e.target.name, true)
                              }
                              name="isScheduled"
                              checked={this.state.isScheduled}
                            />{" "}
                            {lang.getLocale("is-scheduled-check")}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    )}

                    {this.state.isScheduled && (
                      <Col
                        xs="12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <DatePicker
                          onChange={this.onInputChange}
                          HoursOfOperation={this.props.HoursOfOperation}
                          MilitaryTimeFormat={this.props.MilitaryTimeFormat}
                          name="ScheduledDate"
                        />
                      </Col>
                    )}

                    {this.props.Curbside && (
                      <FormGroup
                        style={{
                          direction: "ltr",
                          display: "flex",
                          paddingLeft: 60,
                          paddingRight: 60,
                          margin: 0,
                          alignSelf: "flex-start",
                        }}
                      >
                        <Label>
                          <Input
                            onChange={(e) =>
                              this.onInputChange(
                                e.target.name,
                                e.target.checked
                              )
                            }
                            name="isCurbside"
                            type="checkbox"
                          />
                          {lang.getLocale("is-curbside-check")}
                        </Label>
                      </FormGroup>
                    )}

                    {this.state.isCurbside && (
                      <div>
                        <FullWidthInput
                          name="vehicleModel"
                          onChange={this.onInputChange}
                          placeholder={lang.getLocale("curbside-model")}
                        />
                        <FullWidthInput
                          name="vehicleColor"
                          onChange={this.onInputChange}
                          placeholder={lang.getLocale("curbside-color")}
                        />
                        <FullWidthInput
                          name="vehiclePlate"
                          onChange={this.onInputChange}
                          placeholder={lang.getLocale("curbside-plate")}
                        />
                      </div>
                    )}

                    <FullWidthInput
                      name="Address"
                      onChange={this.onInputChange}
                      invisible={!this.props.isDelivery}
                      placeholder={lang.getLocale("address-delivery")}
                    />

                    <FullWidthInput
                      name="City"
                      onChange={this.onInputChange}
                      invisible={!this.props.isDelivery}
                      placeholder={lang.getLocale("city")}
                    />

                    <FullWidthInput
                      name="Tip"
                      onChange={this.onInputChange}
                      invisible={
                        !this.props.TakeoutTipEnabled &&
                        !this.props.DeliverTipEnabled
                      }
                      placeholder={lang.getLocale("tip")}
                    />

                    <FullWidthInput
                      type="textarea"
                      name="DeliveryInstructions"
                      onChange={this.onInputChange}
                      invisible={!this.props.isDelivery}
                      placeholder={lang.getLocale("delivery-instruction")}
                    />

                    <div
                      dir="ltr"
                      style={{
                        width: "92%",
                        borderRadius: 10,
                        backgroundColor: "#F3F3F3",
                        paddingTop: 5,
                        paddingBottom: 20,

                        margin: "0 auto",
                        marginTop: 15,
                      }}
                    >
                      {this.props.EnableCash && (
                        <FormGroup
                          style={{
                            direction: "ltr",
                            display: "flex",
                            paddingLeft: 60,
                            paddingRight: 60,
                            alignSelf: "flex-start",
                          }}
                        >
                          <Label>
                            <Input
                              onChange={(e) =>
                                this.onInputChange(
                                  e.target.name,
                                  e.target.checked
                                )
                              }
                              name="cash"
                              type="checkbox"
                            />
                            {lang.getLocale("cash-check")}
                          </Label>
                        </FormGroup>
                      )}

                      <FullWidthInput
                        name="Name"
                        onChange={this.onInputChange}
                        placeholder="Name"
                      />

                      {!this.state.cash && (
                        <CreditCardInput
                          cardNumberInputRenderer={({
                            handleCardNumberChange,
                            props,
                          }) => (
                            <div
                              style={{
                                display: "contents",
                              }}
                            >
                              <br />
                              <input
                                {...props}
                                onChange={handleCardNumberChange((e) => {
                                  // console.log("number change", e.target.value);
                                  this.state.ccInfo["number"] = e.target.value;
                                  this.setState({
                                    ccInfo: this.state.ccInfo,
                                    date: Date.now(),
                                    creditCardInfoValid: true,
                                  });
                                  // this.state.creditCardInfoValid = true;
                                })}
                                style={{
                                  position: "inherit",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              />
                              <div
                                dir="rtl"
                                style={{
                                  width: "98%",
                                  textAlign: "right",
                                  fontSize: 16,
                                  position: "absolute",
                                }}
                              >
                                {Locale.getLocale("credit-card-number")}
                              </div>
                            </div>
                          )}
                          cardExpiryInputRenderer={({
                            handleCardExpiryChange,
                            props,
                          }) => (
                            <div
                              style={{
                                display: "contents",
                              }}
                            >
                              <input
                                {...props}
                                onChange={handleCardExpiryChange((e) => {
                                  // console.log("expiry change", e.target.value);
                                  this.state.ccInfo["expiry"] = e.target.value;
                                  this.setState({
                                    ccInfo: this.state.ccInfo,
                                    date: Date.now(),
                                    creditCardInfoValid: true,
                                  });
                                  //this.state.creditCardInfoValid = true;
                                })}
                                style={{
                                  position: "inherit",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              />
                              <div
                                dir="rtl"
                                style={{
                                  width: "98%",
                                  textAlign: "right",
                                  fontSize: 16,
                                  position: "absolute",
                                }}
                              >
                                {Locale.getLocale("credit-card-expiry")}
                              </div>
                            </div>
                          )}
                          cardCVCInputRenderer={({
                            handleCardCVCChange,
                            props,
                          }) => (
                            <div
                              style={{
                                display: "contents",
                              }}
                            >
                              <input
                                {...props}
                                onChange={handleCardCVCChange((e) => {
                                  console.log(
                                    "handleCardCVCChange",
                                    e.target.value
                                  );
                                  this.state.ccInfo["ccv"] = e.target.value;
                                  this.setState({
                                    ccInfo: this.state.ccInfo,
                                    date: Date.now(),
                                  });
                                  //this.state.creditCardInfoValid = true;
                                  this.setState({
                                    creditCardInfoValid: true,
                                  });
                                })}
                                style={{
                                  position: "inherit",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              />
                              <div
                                dir="rtl"
                                style={{
                                  width: "98%",
                                  textAlign: "right",
                                  fontSize: 16,
                                  position: "absolute",
                                }}
                              >
                                {Locale.getLocale("credit-card-cvc")}
                              </div>
                            </div>
                          )}
                          //containerStyle={{ backgroundColor: "#F3F3F3", borderRadius: 10, paddingTop: 10, border:'1px solid red', display:'inline' }}
                          fieldStyle={{
                            backgroundColor: "#F3F3F3",
                            display: "grid",
                            img: { height: 20, marginBottom: 5 },
                            borderRadius: 10,
                          }}
                          inputStyle={{
                            backgroundColor: "#F3F3F3",
                            border: 0,
                            marginBottom: 10,
                            marginTop: 10,
                            height: 30,
                            marginLeft: -9,
                            borderRadius: 10,
                          }}
                          //onError={({ inputName, err }) => console.log(`credit card input error: ${err}`)}
                          cardCVCInputProps={{
                            //onBlur: e => console.log('cvc blur', e),
                            //onChange: e => console.log('cvc change', e),
                            onError: (err) => {
                              console.log(`cvc error: ${err}`);
                              this.setState({
                                creditCardInfoValid: false,
                              });
                            },
                          }}
                          cardExpiryInputProps={{
                            //onBlur: e => console.log('expiry blur', e),
                            //onChange: e => console.log('expiry change', e),
                            onError: (err) => {
                              console.log(`expiry error: ${err}`);
                              this.setState({
                                creditCardInfoValid: false,
                              });
                            },
                          }}
                          cardNumberInputProps={{
                            //onBlur: e => console.log('number blur', e),
                            //onChange: e => console.log('number change', e),
                            onError: (err) => {
                              console.log(`number error: ${err}`);
                              this.setState({
                                creditCardInfoValid: false,
                              });
                            },
                          }}
                          customTextLabels={{
                            invalidCardNumber: Locale.getLocale(
                              "error-invalid-credit-card"
                            ),
                            expiryError: {
                              invalidExpiryDate: Locale.getLocale(
                                "reservation-cc-invalid-expire"
                              ),
                              monthOutOfRange: Locale.getLocale(
                                "reservation-cc-invalid-expiration-value"
                              ),
                              yearOutOfRange: Locale.getLocale(
                                "reservation-cc-past-expiration"
                              ),
                              dateOutOfRange: Locale.getLocale(
                                "reservation-cc-invalid-expiration-date"
                              ),
                            },
                            invalidCvc: Locale.getLocale(
                              "reservation-cc-invalid-cvc"
                            ),
                            cardNumberPlaceholder: "0000 0000 0000 0000",
                            expiryPlaceholder: "00/00",
                          }}
                        />
                      )}
                    </div>
                    <br />
                  </div>
                )}

                <CustomSelect
                  invisible={!this.state.gotCode && !this.props.debug}
                  onChange={this.onInputChange}
                  name="Dinners"
                />

                <FullWidthInput
                  invisible={!this.state.gotCode && !this.props.debug}
                  name="Notes"
                  onChange={this.onInputChange}
                  style={{
                    marginBottom: 20,
                  }}
                  placeholder="Notes"
                />
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              key={this.state.date}
              disabled={
                !this.state.gotCode ||
                (!this.isCCValid() && !this.state.cash) ||
                !this.state.verifyCode ||
                !this.state.Name ||
                (this.props.isDelivery &&
                  (!this.state.Address ||
                    // !this.state.HomeNumber ||
                    !this.state.City))
              }
              color="primary"
              onClick={this.createOrder}
            >
              {this.props.isDelivery
                ? lang.getLocale("order-delivery")
                : lang.getLocale("order-takeout")}
            </Button>{" "}
            <Button color="secondary" onClick={this.props.onClose}>
              {lang.getLocale("back-order")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
