import React, { Suspense } from "react";
import { Container } from "reactstrap";
import MHeader from "./MHeader";
import MFooter from "./MFooter";
import MLicenseAgreement from "./MLicenseAgreement";
import MButton from "./MButton";
//import MDana from "./MDana";
//import MParentCategory from "./MParentCategory";
//import MSearch from "./MSearch";
import MView from "./MView";
import MSpinner from "./MSpinner";
import MSearchResults from "./MSearchResults";
import MBackToTop from "./MBackToTop";
import Config from "./Config";
import MModalSpecial from "./MModalSpecial";

import queryString from "query-string";
import Locale from "./lang";
//import ProductModal from "./MDana/container/ProductModal";
import Data from "./Data";
// import MReviewsModal from "./MReviewsModal";
// import MAddReviewModal from "./MAddReviewModal";
import pkgJson from '../package.json'

import TakeoutModal from "./TakeoutModal";
import MTopTenResults from "./MTopTenResults";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import ClearCache, { useClearCache } from "react-clear-cache";
import lang from "./lang";


var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const MSearch = React.lazy(() => import("./MSearch"));
const MAddReviewModal = React.lazy(() => import("./MAddReviewModal"));
const MReviewsModal = React.lazy(() => import("./MReviewsModal"));
const MParentCategory = React.lazy(() => import("./MParentCategory"));
const MDana = React.lazy(() => import("./MDana"));
const ProductModal = React.lazy(() => import("./MDana/container/ProductModal"));

window.serializeJSON = function (data) {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};

window.scrollInterval = null;

window.scrollToTop = function () {
  if (window.scrollInterval != null) clearInterval(window.scrollInterval);

  let i = window.scrollY;
  window.scrollInterval = setInterval(function () {
    if (i > 0) {
      i = i - 10;
      window.scrollTo(0, i);
    } else {
      clearInterval(window.scrollInterval);
    }
  }, 10);
};
window.scrollToBottom = function () {
  if (window.scrollInterval != null) clearInterval(window.scrollInterval);
  let i = window.scrollY;

  //console.log('scrollToBottom', i, document.body.scrollHeight);
  window.scrollInterval = setInterval(function () {
    if (i < document.body.scrollHeight) {
      i = i + 10;
      window.scrollTo(0, i);
    } else {
      clearInterval(window.scrollInterval);
    }
  }, 10);
};
window.post = function (me, url, p, onLoad) {
  console.log("WINDOW.post", url, p);
  return fetch(url, {
    method: "POST",
    body: this.serializeJSON(p),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => {
      console.log("Response 1 response", response);
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Response 2 data", data);
      if (onLoad != null) {
        onLoad.call(me, data);
      }
    })
    .catch(function (e) {
      console.log("error", e);
    });
};
let deferredPrompt;
let addBtn;

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  addBtn.style.display = "block";

  addBtn.addEventListener("click", (e) => {
    // hide our user interface that shows our A2HS button
    addBtn.style.display = "none";
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      deferredPrompt = null;
    });
  });
});

const AppLoading = ({ children, color }) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({
   // basePath: "/menu/",
  });
  return (
    <div>
      {!isLatestVersion ? (
        <p>
          <h1
            style={{
              marginBottom: 30,
            }}
          >
            {lang.getLocale("update-title")}
          </h1>
          <span
            style={{
              background: color,
              borderRadius: 10,
              border: "3px solid #333",
              fontSize: 40,
              padding: 10,
            }}
          >
            <a
              href="#"
              style={{
                color: "#fff",
              }}
              onClick={(e) => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              {lang.getLocale("update-btn-action")}
            </a>
          </span>
        </p>
      ) : (
        children
      )}
    </div>
  );
};
export default class Main extends React.Component {
  constructor(props) {
    super(props);
    //var id = document.location.search.replace("?id=", '');
    let params = queryString.parse(document.location.search);
    let id = params.id;
    let isTakeout = params.takeout ? true : false;
    let isDelivery = params.delivery ? true : false;
    let isFull = params.combo ? true : false;
    let isSecondPage = params.in ? true : false;
    let legacy = params.legacy ? true : false;
    console.log("constructor", params);
    if (!id) {
      id = "6c279fe1-1480-40a8-af18-a28d758ba983";
    }

    let lang = window.navigator.userLanguage || window.navigator.language;
    // alert(lang); //works IE/SAFARI/CHROME/FF
    let isRTL = false;
    if (lang.indexOf("en") >= 0) {
      lang = "en";
    } else if (lang.indexOf("he") >= 0) {
      lang = "he";
      isRTL = true;
    } else {
      lang = "en";
    }

    console.log("Menu", id);

    Data.isDelivery = isDelivery;
    Data.isTakeout = isTakeout;

    this.state = {
      QR: id,
      Name: "",
      isTakeout,
      isDelivery,
      isFull,
      Color: "#98CA3C",
      DisabledColor: "rgb(201,201,201)",
      backgroundColor: "#FFFFFF",
      isReady: false,
      searched: false,
      searchResultsLocal: [],
      isWifiOpen: false,
      options: [],
      q: "",
      lng: lang,
      isRTL: isRTL,
      legacy: legacy,
      Header: {},
      isFirstPage: !isSecondPage,
      spinner: false,
      width: "100%",
      isLngLoading: false,
      flagsSelection: true,
      SpecialModalOpen: false,
      SpecialModalText: "",
      SpecialModalImage: "",
      PromoVideo: "",
      price: "",
      loadingProduct: false,
      MenuExtrasEnabled: false,
      itemLiked: false,
      itemsLikedArray: [],
      loadingReviewModal: false,
      openReviewModal: false,
      openAddReviewModal: false,
      ReviewerItemID: "",
      ReviewerName: "",
      ReviewerPhone: "",
      ReviewerText: "",
      ReviewsArray: [],
      itemName: "",
      itemReviews: "",
      gettingItemReviews: false,
      btnAnimatedAddReview: {
        height: 24,
        width: 80,
        color: "white",
        cursor: "pointer",
        fontSize: 12,
        textAlign: "center",
        borderRadius: 18,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: "black",
      },
      width: window.innerWidth > 400 ? 375 : "auto",
      topTenItemsArray: [],
      topTenItemsShown: false,
    };
    this.validPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    for (var i = 1; i < 9; i++) {
      this.state.options[i] = false;
    }

    console.log("Main", this.state.QR);

    this.oneLenguageLoaded = false;
    this.specialShown = false;

    Locale.setLang(this.state.lng);
    console.log("locale", this.state.lng);
    // if (window.innerWidth > 400) {
    //   this.state.width = 375;
    // }
  }

  componentDidMount() {
    //fetch('https://menual.azurewebsites.net/ws/Menu.asmx/GetMenu?id=' + this.state.QR)
    this.getDataFromServer();

    addBtn = document.querySelector(".add-button");
    addBtn.style.display = "none";
    //alert(window.innerWidth);
  }

  getDataFromServer = () => {
    console.log("getDataFromServer", Config.apiEndPoint);
    var url =
      Config.apiEndPoint +
      (!this.state.isReady && false
        ? "/ws/Menu.asmx/GetMenuShort"
        : "/ws/Menu.asmx/GetMenuPerformant");
    var p = {
      id: this.state.QR,
      q: this.state.q,
      lng: this.state.lng,
      price: this.state.price,
      takeout: this.state.isTakeout,
      delivery: this.state.isDelivery,
      version : pkgJson.version
    };

    if (this.state.lng == "he") {
      document.getElementsByTagName("body")[0].style.direction = "rtl";
      window.textAlign = "right";
    } else {
      document.getElementsByTagName("body")[0].style.direction = "ltr";
      window.textAlign = "left";
    }

    if (window.UserID != null) {
      p.UserID = window.UserID;
    }
    for (var i = 1; i < 9; i++) {
      p["option" + i] = this.state.options[i];
    }
    if (this.state.q != "") {
      window.gEvent("search", this.state.q);
      this.setState({ spinner: true });
    }
    console.log("getDataFromServer", url, p);

    window.post(this, url, p, (data) => {
      console.log("getDataFromServer RESPONSE", data);
      this.setState(data);
      Data.setData(data);
      let isReady = this.state.isReady;
      this.setState({ isReady: true }, (prevState) => {
        //if (!isReady) this.getDataFromServer();
      });
      this.setState({ spinner: false, loadingProduct: false });
      this.setState({
        isLngLoading: false,
      });
      window.Name = data.Header.Name;
      window.backgroundColor = data.backgroundColor;
      document.body.style.backgroundColor = data.backgroundColor;
      if (data.UserID != null) window.UserID = data.UserID;
      window.gtag("set", "dimension1", window.Name);
      window.gEvent("open", "menu");

      if (data.MenuSpecialEnabled && !this.specialShown) {
        this.specialShown = true;
        setTimeout(() => {
          this.setState({
            SpecialModalText: data.MenuSpecialText,
            SpecialModalOpen: true,
            SpecialModalImage:
              data.MenuSpecialImage != null
                ? document.location.hostname == "menual.menual.net"
                  ? data.MenuSpecialImage
                  : data.MenuSpecialImage.replace("menual.", "menualdev.")
                : null,
          });
        }, data.MenuSpecialDelay * 1000);
      }

      this.buildManifestData(data);

      if (data.SearchResults) {
        this.setState({
          searchResultsLocal: data.SearchResults,
        });
      }

      this.loadBackendLenguages(data);
    });
  };

  buildManifestData = (data) => {
    let url = document.location.search;
    console.log("document.location.search", url, data);
    var myDynamicManifest = {
      name: "Menual " + data.Header.Name,
      short_name: data.Header.Name,
      description: "The Smart Menu done right",
      start_url: document.location.origin + "/menu/" + url,
      background_color: "#000000",
      display: "standalone",
      theme_color: "#000",
      icons: [
        {
          src:
            Config.apiEndPoint.replace("menualdev", "menual") +
            "/logos/" +
            data.LocationID +
            "-144-.jpg",
          type: "image/png",
          sizes: "144x144",
        },
        {
          src:
            Config.apiEndPoint.replace("menualdev", "menual") +
            "/logos/" +
            data.LocationID +
            "-192-.jpg",
          type: "image/jpg",
          sizes: "192x192",
          //purpose: "any maskable",
        },
        {
          src:
            Config.apiEndPoint.replace("menualdev", "menual") +
            "/logos/" +
            data.LocationID +
            "-512-.jpg",
          sizes: "512x512",
          type: "image/png",
          purpose: "any maskable",
        },
      ],
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    let theManifest = document.querySelector("#my-manifest-placeholder");
    console.log("The manifest = ", theManifest);
    theManifest.setAttribute("href", manifestURL);

    let appleTouchIcon = document.querySelector("#apple-touch-icon");
    appleTouchIcon.setAttribute(
      "href",
      Config.apiEndPoint.replace("menualdev", "menual") +
        "/logos/" +
        data.LocationID +
        ".jpg"
    );
  };

  setAddingProductLoading(data) {
    console.log("setAddingProductLoading", data);

    if (
      this.state.MenuExtrasEnabled ||
      this.state.isTakeout ||
      this.state.isDelivery
    ) {
      this.setState({
        loadingProduct: true,
        showAddingProduct: true,
        productData: data,
      });
    } else {
      this.setState({
        loadingProduct: true,
      });

      var url = Config.apiEndPoint + "/ws/Menu.asmx/AddToList";
      var p = {
        ItemID: data.ID,
        AddID : data.AddID,
        UserID: window.UserID,
      };
      console.log("Requesting", url, p);
      window.post(this, url, p, (response) => {
        console.log("MProduct Add Response", response);
        if (response.added) {
          this.getDataFromServer();
        }
      });
    }
  }

  closeAddingProduct = () => {
    this.setState({
      showAddingProduct: false,
      productData: null,
      loadingProduct: false,
    });
  };

  getVal(v) {
    if (v == null) {
      return "";
    } else {
      return v;
    }
  }
  onClick() {
    console.log("onClick");
  }
  onChangeOption(options, price) {
    console.log("onChange", options, price);
    this.state.options = options;
    this.state.price = price;
    this.state.q = "";
    this.getDataFromServer();
  }
  onSearchClick(q) {
    console.log("onSearchClick", q);
    this.state.options = [];
    this.state.price = "";
    this.state.q = q;
    this.setState({
      searched: true,
    });
    this.getDataFromServer();
  }
  onWifiClose() {
    this.setState({ isWifiOpen: false });
  }
  onFlagClick(lng) {
    this.state.lng = lng;
    this.getDataFromServer();
    if (this.refs.ParentCategory != null) {
      this.refs.ParentCategory.reset();
    }
    this.setState({
      isLngLoading: true,
    });
    Locale.setLang(this.state.lng);
    console.log("locale", this.state.lng);
    window.gEvent("lng", lng);
  }

  onGoToMenu() {
    this.setState({ isFirstPage: false });
    //this.getDataFromServer();
    window.gEvent("click", "goToMenu");
  }
  onGoToTakeoutMenu() {
    window.gEvent("click", "goToTakeoutMenu");
    let url = document.location.href;
    console.log("The url is", url);

    if (url.includes("combo")) {
      url = url.replace("combo", "takeout");
      url += "&in=true";
    }

    //var win = window.open(url, '_blank');
    var win = window.open(url, "_self");
    win.focus();
  }
  onGoToDeliveryMenu() {
    window.gEvent("click", "goToDeliveryMenu");
    window.gEvent("click", "goToTakeoutMenu");
    let url = document.location.href;
    console.log("The url is", url);

    if (url.includes("combo")) {
      url = url.replace("combo", "delivery");
      url += "&in=true";
    }

    //var win = window.open(url, '_blank');
    var win = window.open(url, "_self");
    win.focus();
  }

  onShowCategory() {
    console.log("onShowCategory");

    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
    // this.getDataFromServer();
    // if (this.refs.ParentCategory != null) {
    //     this.refs.ParentCategory.reset();
    // }
  }

  loadBackendLenguages(data) {
    console.log("loadBackendLenguages", data);

    if (!this.oneLenguageLoaded) {
      this.oneLenguageLoaded = true;

      let header = data.Header;

      if (header.MenuEnglish && !header.MenuHebrew) {
        this.setState({
          flagsSelection: false,
        });
        this.onFlagClick("en");
      } else if (!header.MenuEnglish && header.MenuHebrew) {
        this.setState({
          flagsSelection: false,
        });
        this.onFlagClick("he");
      }
    }
  }

  onCloseSpecialModal() {
    console.log("onCloseSpecialModal");
    this.setState({ SpecialModalOpen: false });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onClearSearch() {
    this.setState({
      searchResultsLocal: [],
      searched: false,
    });
  }

  toggleItemLiked(item) {
    // console.log('toggleItemLiked', item)
    if (this.state.itemsLikedArray.length > 0) {
      for (let i = 0; i < this.state.itemsLikedArray.length; i++) {
        if (this.state.itemsLikedArray[i].ID === item.ID) {
          let tempArray = this.state.itemsLikedArray;
          var removeIndex = tempArray
            .map(function (i) {
              return i.ID;
            })
            .indexOf(item.ID);
          tempArray.splice(removeIndex, 1);
          this.setState(
            {
              itemsLikedArray: tempArray,
            },
            console.log(
              "DELETED ITEMS LIKED ARRAY: ",
              this.state.itemsLikedArray
            )
          );
          this.postItemLikedUrl(item.ID, "/ws/Menu.asmx/RemoveItemLike");
          return;
        }
      }
    }
    var tempArray = { ID: item.ID, Value: item.LikesCount };
    this.setState(
      {
        itemsLikedArray: [...this.state.itemsLikedArray, tempArray],
      },
      console.log("ITEMS LIKED ARRAY: ", this.state.itemsLikedArray)
    );
    this.postItemLikedUrl(item.ID, "/ws/Menu.asmx/AddItemLike");
  }

  postItemLikedUrl(itemID, urlString) {
    var url = Config.apiEndPoint + urlString;
    var p = {
      ItemID: itemID,
    };
    this.setState({
      spinner: true,
    });
    window.post(this, url, p, (data) => {
      // console.log('url', url)
      // console.log("toggleItemLiked RESPONSE", data)
      if (data.ok) {
        this.getDataFromServer();
      }
    });
  }

  onChangeText(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  getItemReviews(item) {
    console.log("getItemReview", item);
    var url = Config.apiEndPoint + "/ws/Menu.asmx/GetItemReviews";
    var p = {
      ItemID: item.ID || item,
    };
    console.log("Requesting", url, p);
    this.setState({ gettingItemReviews: true });
    window.post(this, url, p, (data) => {
      console.log("getItemReviews data", data);
      if (data.ok || (!data.ok && data.Reviews)) {
        this.setState({
          ReviewsArray: data.Reviews,
          gettingItemReviews: false,
        });
        if (!this.state.openReviewModal) {
          this.onOpenReviewModal(item);
        }
      }
    });
  }

  onOpenReviewModal(item) {
    this.setState({
      openReviewModal: true,
      ReviewerItemID: item.ID,
      itemName: item.Name,
      itemReviews: item.Reviews,
    });
  }

  onCloseReviewModal() {
    this.setState({
      openReviewModal: false,
      ReviewerItemID: "",
    });
  }

  onOpenAddReviewModal() {
    this.setState({
      openAddReviewModal: true,
    });
  }

  onCloseAddReviewModal() {
    this.setState({
      openAddReviewModal: false,
      ReviewerName: "",
      ReviewerPhone: "",
      ReviewerText: "",
      loadingReviewModal: false,
    });
  }

  addItemReview() {
    console.log("addItemReview");
    let isValidPhone = this.validPhone.test(this.state.ReviewerPhone);
    if (this.state.ReviewerText === "") {
      alert("Missing parameters");
      return;
    }
    if (this.state.MenuUsersInfoRequired) {
      if (this.state.ReviewerPhone === "" || this.state.ReviewerName === "") {
        alert("Missing parameters");
        return;
      }
      if (!isValidPhone) {
        alert("Phone Number is not valid");
        return;
      }
    }
    var url = Config.apiEndPoint + "/ws/Menu.asmx/AddItemReview";
    var p = {
      ItemID: this.state.ReviewerItemID,
      ReviewerName: this.state.ReviewerName,
      ReviewerPhone: this.state.ReviewerPhone,
      ReviewText: this.state.ReviewerText,
    };
    console.log("Requesting", url, p);
    this.setState({ loadingReviewModal: true });
    window.post(this, url, p, (data) => {
      console.log("addItemReview data", data);
      if (data.ok) {
        this.getItemReviews(this.state.ReviewerItemID);
        this.onCloseAddReviewModal();
      } else {
        this.onCloseAddReviewModal();
        alert("Error saving review");
      }
    });
  }

  handleEvent(e) {
    if (e.type === "mousedown") {
      this.setState({
        btnAnimatedAddReview: {
          height: 24,
          width: 80,
          color: "white",
          cursor: "pointer",
          fontSize: 12,
          textAlign: "center",
          borderRadius: 18,
          padding: 4,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: "gray",
        },
      });
    } else if (e.type === "mouseup") {
      setTimeout(() => {
        this.setState({
          btnAnimatedAddReview: {
            height: 24,
            width: 80,
            color: "white",
            cursor: "pointer",
            fontSize: 12,
            textAlign: "center",
            borderRadius: 18,
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: "black",
          },
        });
      }, 100);
    }
  }

  getDayOfTheWeek = () => {
    return;
  };

  debugHoursLogs = (restaurantDate, hourOfOperation) => {
    console.log("restaurantDate.day()", restaurantDate.day());
    console.log("restaurantDate.hour()", restaurantDate.hour());
    console.log("restaurantDate.minute()", restaurantDate.minute());

    console.log("====hourOfOperation.Day", hourOfOperation.Day);
    console.log("====hourOfOperation.StartHour", hourOfOperation.StartHour);
    console.log("====hourOfOperation.StartMinute", hourOfOperation.StartMinute);

    console.log("****hourOfOperation.Day", hourOfOperation.Day);
    console.log("****hourOfOperation.EndHour", hourOfOperation.EndHour);
    console.log("****hourOfOperation.EndMinute", hourOfOperation.EndMinute);
  };

  formatHours = (startMoment, endMoment) => {
   
    let format = "";
    if (this.hoursOfOperationString) {
      format = " | \n\n\n";
    }
      return format + (
        startMoment.format("ddd") +
        " " +
        startMoment.format(this.state.MilitaryTimeFormat ? "HH" : "hh") +
        ":" +
        startMoment.format("mm A") +
        " - " +
        endMoment.format("ddd") +
        " " +
        endMoment.format(this.state.MilitaryTimeFormat ? "HH" : "hh") +
        ":" +
        endMoment.format("mm A") 
        
      );
  };

  isTakeoutOrDeliveryEnabled = (Type) => {
    

    if (!this.state["is" + Type]) {
      console.log("is" + Type + "OrDeliveryEnabled return before", false);
      return false;
    }
    this.hoursOfOperationString = "";
    let isEnabled = false;
    if (this.state[Type + "HoursOfOperation"]) {
      console.log(
        `Object.keys(this.state.${Type}HoursOfOperation).length`,
        Object.keys(this.state[Type + "HoursOfOperation"]).length
      );
      let restaurantDate = dayjs(
        this.state.RestaurantHour,
        "YYYY-MM-DD kk:mm"
      );
      this.customRestaurantData = restaurantDate.format("ddd HH:mm")
      //2020-12-27T09:51:08.2550531-05:00
      //restaurantDate = restaurantDate.hour(10);
      //restaurantDate = restaurantDate.minute(24);
      let startMoment = dayjs();
      
      if (Object.keys(this.state[Type + "HoursOfOperation"]).length) {
        Object.keys(this.state[Type + "HoursOfOperation"]).forEach((key) => {
          const hourOfOperation = this.state[Type + "HoursOfOperation"][key];

          if(!hourOfOperation.Enabled){
            return;
          }
          
          startMoment = startMoment.day(hourOfOperation.Day);
          startMoment = startMoment.hour(hourOfOperation.StartHour);
          startMoment = startMoment.minute(hourOfOperation.StartMinute);

          let endMoment = dayjs();
          endMoment = endMoment.day(hourOfOperation.Day);
          endMoment = endMoment.hour(hourOfOperation.EndHour);
          endMoment = endMoment.minute(hourOfOperation.EndMinute);
          this.hoursOfOperationString += this.formatHours(
            startMoment,
            endMoment
          );

          console.log("this.state.RestaurantHour", this.state.RestaurantHour);
          // console.log("this.state.LocationID", this.state.LocationID);
          // console.log("restaurantDate", restaurantDate.toDate());
          let convertedRestaurantDay = restaurantDate.day();
          if (convertedRestaurantDay == 0) {
            convertedRestaurantDay = 7
          }
           
          if (hourOfOperation.Day == convertedRestaurantDay) {
            this.debugHoursLogs(restaurantDate, hourOfOperation);
            //console.log("startMoment", startMoment);
            //console.log("endMoment", endMoment);
            console.log("restaurantDate.day()", convertedRestaurantDay);
            console.log("restaurantDate.hour()", restaurantDate.hour());
            console.log("restaurantDate.minute()", restaurantDate.minute());
            console.log("hourOfOperation.Day", hourOfOperation.Day);
            console.log("hourOfOperation.StartHour", hourOfOperation.StartHour);
            console.log("hourOfOperation.StartMinute", hourOfOperation.StartMinute);
            let startDate = dayjs(restaurantDate.format()).hour(hourOfOperation.StartHour).minute(hourOfOperation.StartMinute).second(0)
            let endDate = dayjs(restaurantDate.format()).hour(hourOfOperation.EndHour).minute(hourOfOperation.EndMinute)
            console.log("restaurantDate", restaurantDate.format());
            console.log("dayjs startDate", startDate.format())
            console.log("dayjs endDate", endDate.format())
            console.log("dayjs startDate.isBefore(restaurantDate)", startDate.isBefore(restaurantDate))
            console.log("dayjs endDate.isAfter(restaurantDate)", endDate.isAfter(restaurantDate))
            if (
              // (hourOfOperation.StartHour == restaurantDate.hour() &&
              //   hourOfOperation.StartMinute <= restaurantDate.minute()) ||
              // (hourOfOperation.StartMinute <= restaurantDate.minute() &&
              //   hourOfOperation.StartHour < restaurantDate.hour() &&
              //   hourOfOperation.StartMinute <= restaurantDate.minute() &&
              //   (hourOfOperation.EndHour > restaurantDate.hour() ||
              //     (hourOfOperation.EndHour >= restaurantDate.hour() &&
              //       hourOfOperation.EndMinute > restaurantDate.minute())))
              startDate.isBefore(restaurantDate) && endDate.isAfter(restaurantDate)
            ) {
              isEnabled = true;
            }
          }
        });
      } else {
        isEnabled = true;
      }
    }
    console.log("isTakeoutOrDeliveryEnabled", isEnabled);
    return isEnabled;
  };

  saveTopTenItems(topTenItems) {
    console.log("TOP TEN ITEMS ARRAY: ", topTenItems);
    this.setState({
      topTenItemsArray: topTenItems,
      topTenItemsShown: true,
      spinner: false,
    });
  }

  onHideTopTenItems() {
    this.setState({
      topTenItemsArray: [],
      topTenItemsShown: false,
    });
  }

  onShowSpinner() {
    this.setState({
      spinner: true,
    });
  }

  render() {
    // console.log(
    //   "Main Menu ==>",
    //   this.state.isTakeout,
    //   this.state.isDelivery,
    //   this.state.isFull
    // );
    // console.log("isFirstPage", this.state.isFirstPage);

    let direction = "rtl";
    if (this.state.lng == "he") {
      direction = "rtl";
      window.textAlign = "right";
    } else {
      direction = "ltr";
      window.textAlign = "left";
    }

    let addEnabled =
      this.state.MenuAssistantEnabled ||
      this.state.isTakeout ||
      this.state.isDelivery;

    // let btnText = this.state.btnText;
    // if (this.state.MenuBtnLabel != ""){
    //     btnText = this.state.MenuBtnLabel;
    // }

    let btnText = this.state.btnText;
    let termsText = this.state.Header.TermsText;
    if (this.state.isTakeout) {
      if (this.state.lng == "en") {
        btnText = "Go to TakeOut Menu".toUpperCase();
        if (termsText) {
          termsText = termsText.replace(
            "GO TO MENU",
            "Go to TakeOut Menu".toUpperCase()
          );
        }
      } else if (this.state.lng == "he") {
        btnText = "מעבר לתפריט איסוף עצמי";
      }
    } else if (this.state.isDelivery) {
      if (this.state.lng == "en") {
        btnText = "Go to Delivery Menu".toUpperCase();
        if (termsText) {
          termsText = termsText.replace(
            "GO TO MENU",
            "Go to Delivery Menu".toUpperCase()
          );
        }
      } else if (this.state.lng == "he") {
        btnText = "מעבר לתפריט משלוחים";
      }
    }

    let btnText1 = this.state.btnText;
    let btnText2 = this.state.btnText;

    if (this.state.isFull) {
      if (this.state.lng == "en") {
        btnText1 = "Go to TakeOut Menu".toUpperCase();
        btnText2 = "Go to Delivery Menu".toUpperCase();
        if (termsText) {
          termsText = termsText.replace(
            "GO TO MENU",
            "Go to TakeOut Menu".toUpperCase() +
              " or " +
              "Go to Delivery Menu".toUpperCase()
          );
        }
      } else if (this.state.lng == "he") {
        btnText1 = "מעבר לתפריט איסוף עצמי";
        btnText2 = "מעבר לתפריט משלוחים";
      }
    }

    return (
      <Container
        dir={direction}
        className="App"
        style={{ padding: 0, width: this.state.width }}
      >
        <AppLoading color={this.state.Color}>
          <SkeletonTheme>
            {/* MENU (Second Page) */}
            {this.state.isReady && (
              <MHeader
                flagsSelection={
                  this.state.isFirstPage ? false : this.state.flagsSelection
                }
                lng={this.state.lng}
                isTakeout={this.state.isTakeout}
                isDelivery={this.state.isDelivery}
                direction={direction}
                showControls={!this.state.isFirstPage}
                onFlagClick={this.onFlagClick.bind(this)}
                data={this.state.Header}
                ColorText={this.state.ColorText}
                color={this.state.Color}
                LocationID={this.state.LocationID}
                Name={this.state.Name}
                isLngLoading={this.state.isLngLoading}
                MenuSwitchEnabled={this.state.MenuSwitchTakeoutDelivery}
                GoDeliveryMenu={this.state.GoDeliveryMenu}
                GoTakeOutMenu={this.state.GoTakeOutMenu}
                menuMembersClubEnabled={this.state.MenuMembersClubEnabled}
                menuWiFiEnabled={this.state.MenuWiFiEnabled}
                menuSocialMediaEnabled={this.state.MenuSocialMediaEnabled}
                topTenEnabled={this.state.MenuTopTenEnabled}
                saveTopTenItems={this.saveTopTenItems.bind(this)}
                showSpinner={this.onShowSpinner.bind(this)}
              ></MHeader>
            )}

            <Suspense
              fallback={
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              }
            >
              <MView visible={this.state.isReady && !this.state.isFirstPage}>
                {/* <MHeader
              flagsSelection={this.state.flagsSelection}
              lng={this.state.lng}
              isTakeout={this.state.isTakeout}
              isDelivery={this.state.isDelivery}
              direction={direction}
              showControls={true}
              onFlagClick={this.onFlagClick.bind(this)}
              data={this.state.Header}
              ColorText={this.state.ColorText}
              color={this.state.Color}
              LocationID={this.state.LocationID}
              Name={this.state.Name}
              isLngLoading={this.state.isLngLoading}
              MenuSwitchEnabled={this.state.MenuSwitchTakeoutDelivery}
              GoDeliveryMenu={this.state.GoDeliveryMenu}
              GoTakeOutMenu={this.state.GoTakeOutMenu}
              menuMembersClubEnabled={this.state.MenuMembersClubEnabled}
              menuWiFiEnabled={this.state.MenuWiFiEnabled}
              menuSocialMediaEnabled={this.state.MenuSocialMediaEnabled}
              topTenEnabled={this.state.MenuTopTenEnabled}
              saveTopTenItems={this.saveTopTenItems.bind(this)}
              showSpinner={this.onShowSpinner.bind(this)}
            ></MHeader> */}

                <MSearch
                  data={this.state.SearchOptions}
                  direction={direction}
                  SearchText={this.state.SearchText}
                  AdvancedSearchText={this.state.AdvancedSearchText}
                  color={this.state.Color}
                  disabledColor={this.state.DisabledColor}
                  searched={this.state.searched}
                  loading={this.state.spinner}
                  clearSearchText={this.state.ClearSearch}
                  onClearSearch={this.onClearSearch.bind(this)}
                  MoneySign={this.state.MoneySign}
                  onSearchClick={this.onSearchClick.bind(this)}
                  onChangeOption={this.onChangeOption.bind(this)}
                  shopModeEnabled={this.state.ShopMode}
                ></MSearch>

                <MSpinner visible={this.state.spinner}></MSpinner>

                <MSearchResults
                  data={this.state.searchResultsLocal}
                  noResultsText={this.state.NoResults}
                  direction={direction}
                  addBtnText={this.state.addBtnText}
                  searched={this.state.searched}
                  loading={this.state.spinner}
                  setAddingProductLoading={this.setAddingProductLoading.bind(
                    this
                  )}
                  getDataFromServer={this.getDataFromServer.bind(this)}
                  addEnabled={addEnabled}
                ></MSearchResults>

                {this.state.MenuTopTenEnabled &&
                  this.state.topTenItemsArray.length > 0 && (
                    <MTopTenResults
                      items={this.state.topTenItemsArray}
                      addBtnText={this.state.addBtnText}
                      direction={direction}
                      setAddingProductLoading={this.setAddingProductLoading.bind(
                        this
                      )}
                      getDataFromServer={this.getDataFromServer.bind(this)}
                      addEnabled={addEnabled}
                      loading={this.state.spinner}
                      itemsShown={this.state.topTenItemsShown}
                      onHideItems={this.onHideTopTenItems.bind(this)}
                    />
                  )}

                <MParentCategory
                  ref="ParentCategory"
                  direction={direction}
                  setAddingProductLoading={this.setAddingProductLoading.bind(
                    this
                  )}
                  getDataFromServer={this.getDataFromServer.bind(this)}
                  data={this.state.ParentCategories}
                  allOpen={this.state.ParentCategoriesOpen}
                  categoriesOpen={this.state.CategoriesOpen}
                  color={this.state.Color}
                  addBtnText={this.state.addBtnText}
                  addEnabled={addEnabled}
                  toggleItemLiked={this.toggleItemLiked.bind(this)}
                  itemsLikedArray={this.state.itemsLikedArray}
                  getItemReviews={this.getItemReviews.bind(this)}
                  onOpenReviewModal={this.onOpenReviewModal.bind(this)}
                  menuLikesEnabled={this.state.MenuLikesEnabled}
                  menuReviewsEnabled={this.state.MenuReviewsEnabled}
                  menuUsersInfoRequired={this.state.MenuUsersInfoRequired}
                ></MParentCategory>

                <MReviewsModal
                  isOpen={this.state.openReviewModal}
                  itemName={this.state.itemName}
                  reviews={this.state.itemReviews}
                  reviewsArray={this.state.ReviewsArray}
                  onCloseModal={this.onCloseReviewModal.bind(this)}
                  loading={this.state.gettingItemReviews}
                  direction={direction} //Work with the fields direction
                  onOpenAddReviewModal={this.onOpenAddReviewModal.bind(this)}
                  handleEvent={this.handleEvent.bind(this)}
                  btnAnimatedStyle={this.state.btnAnimatedAddReview}
                />

                <MAddReviewModal
                  isAddReviewOpen={this.state.openAddReviewModal}
                  onChangeText={this.onChangeText.bind(this)}
                  onAddReview={this.addItemReview.bind(this)}
                  loading={this.state.loadingReviewModal}
                  direction={direction} //Work with the fields direction
                  handleEvent={this.handleEvent.bind(this)}
                  onCloseAddReviewModal={this.onCloseAddReviewModal.bind(this)}
                />

                <MLicenseAgreement visible={false}></MLicenseAgreement>

                {addEnabled && (
                  <MDana
                    visible={true}
                    LoadingProduct={this.state.loadingProduct}
                    LocationName={this.state.Header.Name}
                    TList={this.state.TList}
                    restaurantHour={this.customRestaurantData}
                    WhoAreWeText={this.state.WhoAreWeText}
                    HostWelcomeTitle={this.state.HostWelcomeTitle}
                    HostWelcomeText={this.state.HostWelcomeText}
                    DanaText={this.state.DanaText}
                    CartIcon={this.state.CartIcon}
                    CallDana={this.state.CallDana}
                    MoneySign={this.state.MoneySign}
                    EnableCash={this.state.EnableCash}
                    EnableScheduled={this.state.ScheduledTakeout}
                    TakeoutTipEnabled={this.state.TakeoutTipEnabled}
                    DeliverTipEnabled={this.state.DeliverTipEnabled}
                    TotalOrder={this.state.TotalOrder}
                    AboutUs={this.state.AboutUs}
                    isDelivery={this.state.isDelivery}
                    Order={this.state.Order}
                    lang={this.state.lng}
                    DanaName={this.state.DanaName}
                    DanaIcon={this.state.DanaIcon}
                    TableID={this.state.QR}
                    MilitaryTimeFormat={this.state.MilitaryTimeFormat}
                    TakeoutNotice={this.state.TakeoutNotice}
                    DeliveryNotice={this.state.DeliveryNotice}
                    DeliveryFee={
                      this.state.isTakeout ? 0 : this.state.DeliveryFee
                    }
                    OrderBill={this.state.OrderBill}
                    Takeout={this.state.Takeout}
                    isTakeout={this.state.isTakeout}
                    Curbside={this.state.Curbside}
                    takeoutHours={this.isTakeoutOrDeliveryEnabled(this.state.isDelivery ? "Delivery" : "Takeout")}
                    HoursOfOperation={this.state.isDelivery ? this.state.DeliveryHoursOfOperation : this.state.TakeoutHoursOfOperation}
                    takeoutHoursString={this.hoursOfOperationString}
                    LocationID={this.state.LocationID}
                    getDataFromServer={this.getDataFromServer.bind(this)}
                    requestBillEnabled={this.state.MenuShowRequestBill}
                    showTotalEnabled={this.state.MenuShowTotal}
                    Color={this.state.Color}
                    ColorText={this.state.ColorText}
                    direction={"rtl"}
                  ></MDana>
                )}

                <MFooter
                  width={this.state.width}
                  category={true}
                  categoryClick={this.onShowCategory.bind(this)}
                ></MFooter>

                <MBackToTop></MBackToTop>
              </MView>
              <ProductModal
                isOpen={this.state.showAddingProduct}
                getDataFromServer={this.getDataFromServer}
                data={this.state.productData}
                isRTL={this.state.isRTL}
                MoneySign={this.state.MoneySign}
                onClose={this.closeAddingProduct}
              />
            </Suspense>

            {/* WELCOME (First Page) */}

            <MView visible={this.state.isReady && this.state.isFirstPage}>
              {/* <MHeader
              flagsSelection={this.state.flagsSelection}
              lng={this.state.lng}
              showControls={false}
              onFlagClick={this.onFlagClick.bind(this)}
              data={this.state.Header}
              ColorText={this.state.ColorText}
              color={this.state.Color}
              LocationID={this.state.LocationID}
              Name={this.state.Name}
              isLngLoading={this.state.isLngLoading}
              isTakeout={this.state.isTakeout}
            ></MHeader> */}

              {!this.state.isFull && (
                <MButton
                  text={btnText}
                  color={this.state.Color}
                  onClick={this.onGoToMenu.bind(this)}
                ></MButton>
              )}

              {this.state.isFull && (
                <MButton
                  text={btnText1}
                  color={this.state.Color}
                  onClick={this.onGoToTakeoutMenu.bind(this)}
                ></MButton>
              )}
              {this.state.isFull && (
                <MButton
                  text={btnText2}
                  color={this.state.Color}
                  onClick={this.onGoToDeliveryMenu.bind(this)}
                ></MButton>
              )}

              <div style={{ fontSize: 16, padding: 20 }}>
                {this.state.FirstPageText}
              </div>

              <MView visible={this.state.PromoVideo}>
                <iframe
                  width="100%"
                  src={this.state.PromoVideo + "?autoplay=1&mute=1"}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </MView>

              <MLicenseAgreement
                TermsLink={this.state.Header.TermsLink}
                text={termsText}
                visible={true}
              ></MLicenseAgreement>
              <MFooter width={this.state.width} category={null}></MFooter>
            </MView>

            <MModalSpecial
              visible={this.state.SpecialModalOpen}
              onClose={this.onCloseSpecialModal.bind(this)}
              textTitle={this.state.SpecialModalText}
              imageToShow={this.state.SpecialModalImage}
              text={"Example text"}
              width={this.state.width}
            />
            {!this.state.isReady && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100vh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {/* <TakeoutModal
            takeoutList={[]}
            lang={this.props.lang}
            TableID={"6c279fe1-1480-40a8-af18-a28d758ba983"}
            MoneySign={"$"}
            isDelivery={this.state.isDelivery}
            isTakeout={this.state.isTakeout}
            isOpen={true}
            debug={true}
            EnableCash={true}
            Curbside={this.state.Curbside}
            MilitaryTimeFormat={this.state.MilitaryTimeFormat}
            EnableScheduled={true}
            HoursOfOperation={this.state.TakeoutHoursOfOperation}
            TakeoutNotice={this.state.TakeoutNotice}
            DeliveryNotice={this.state.DeliveryNotice}
            DeliveryFee={this.state.DeliveryFee}
            TotalOrder={10 + this.state.DeliveryFee}
            onClose={() => {}}
          /> */}
          </SkeletonTheme>
        </AppLoading>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="add-button btn btn-success btn-large"
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
              fontSize: 38,
            }}
          >
            {lang.getLocale("add-to-homescreen")}
          </button>
        </div>

        <div style={{ height: 200 }}></div>
        <style>{`
              .add-button {
                
                width : auto;
                text-align:center !important;
                
                
              }


              .lds-default {
                  display: inline-block;
                  position: relative;
                  width: 80px;
                  height: 80px;
                }
                .lds-default div {
                  position: absolute;
                  width: 6px;
                  height: 6px;
                  background: #333;
                  border-radius: 50%;
                  animation: lds-default 1.2s linear infinite;
                }
                .lds-default div:nth-child(1) {
                  animation-delay: 0s;
                  top: 37px;
                  left: 66px;
                }
                .lds-default div:nth-child(2) {
                  animation-delay: -0.1s;
                  top: 22px;
                  left: 62px;
                }
                .lds-default div:nth-child(3) {
                  animation-delay: -0.2s;
                  top: 11px;
                  left: 52px;
                }
                .lds-default div:nth-child(4) {
                  animation-delay: -0.3s;
                  top: 7px;
                  left: 37px;
                }
                .lds-default div:nth-child(5) {
                  animation-delay: -0.4s;
                  top: 11px;
                  left: 22px;
                }
                .lds-default div:nth-child(6) {
                  animation-delay: -0.5s;
                  top: 22px;
                  left: 11px;
                }
                .lds-default div:nth-child(7) {
                  animation-delay: -0.6s;
                  top: 37px;
                  left: 7px;
                }
                .lds-default div:nth-child(8) {
                  animation-delay: -0.7s;
                  top: 52px;
                  left: 11px;
                }
                .lds-default div:nth-child(9) {
                  animation-delay: -0.8s;
                  top: 62px;
                  left: 22px;
                }
                .lds-default div:nth-child(10) {
                  animation-delay: -0.9s;
                  top: 66px;
                  left: 37px;
                }
                .lds-default div:nth-child(11) {
                  animation-delay: -1s;
                  top: 62px;
                  left: 52px;
                }
                .lds-default div:nth-child(12) {
                  animation-delay: -1.1s;
                  top: 52px;
                  left: 62px;
                }
                @keyframes lds-default {
                  0%, 20%, 80%, 100% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.5);
                  }
                }

            `}</style>
      </Container>
    );
  }
}
