import React from "react";
import { Spinner } from "reactstrap";

export default class MSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.visible === false) {
      return null;
    }

    return (
      <Spinner style={{ width: "3rem", height: "3rem", margin: 20 }}></Spinner>
    );
  }
}
