import React from 'react';
import { Container, Row, Col } from 'reactstrap';



export default class MBackToTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:false,
        };
        //Get the button
        //var mybutton = document.getElementById("myBtn");
        var me = this;
        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function () { me.scrollFunction() };

       
        // When the user clicks on the button, scroll to the top of the document
        /*
        function topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
        */
    }
    scrollFunction() {
        //console.log('scroll', document.body.scrollTop);
        
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            this.setState({visible: true});
        } else {
            this.setState({ visible: false });
        }
        
    }
    topFunction() {
        //window.scrollToTop();
        
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        
        //document.body.scrollTop = 0;
        //document.documentElement.scrollTop = 0;
    }
    
    render() {
        if(this.state.visible == false){
            return null;
        }    
        return (
            <div style={{ width: 50, height: 50, backgroundColor: window.backgroundColor, fontSize:33,color:'black', position: "fixed", zIndex: 99, bottom: 140, opacity: 0.8 }} onClick={this.topFunction.bind(this)}>
               ▲
            </div>
        );
    }
}