class Data {
  constructor() {
    this.takeoutItems = {};
    this.menuData = {};
    this.version = "0"
  }

  getTakeoutItems() {
    return Object.assign({}, this.takeoutItems);
  }

  setTakeoutItems(takeoutItems) {
    this.takeoutItems = Object.assign({}, takeoutItems);
  }

  setData(data) {
    this.menuData = data;
  }

  setVersion(version){
    this.version = version
  }
  getVersion(){
    return this.version
  }

  get() {
    return this.menuData;
  }
}

export default new Data();
