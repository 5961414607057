// @flow
import en from './en'
import he from './he'

class Lang {

    constructor(){
        this.lang = {}
    }
    
    setLang(lang){
        console.log("setLang", lang)
        
        switch(lang){
            case "en":
                this.lang = en
                break
            default:
                this.lang = he
                break;
        }
        
    }

    getLocale = (string) => {
        let translation = this.lang[string]
        if(!translation){
            return "No translation found for " + string
        }
        return translation
    }
}

export default new Lang()