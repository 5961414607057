import React from "react";
import packageJSON from '../package.json'
import Data from "./Data";
export default class MLicenseAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    Data.setVersion(packageJSON.version)
  }
  onClick() {
    window.open(this.props.TermsLink);
  }
  render() {
    if (this.props.visible === false) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: 30,
          position: "fixed",
          width: 375,
          height: 83,
          // position : "relative",
          flexDirection : 'column',
          backgroundColor: "#F3F3F3",
        }}
        onClick={this.onClick.bind(this)}
      >
        <span style={{
          position : "absolute",
          top : 2,
          fontSize : 10,
          right : 2
        }}>{packageJSON.version}</span>
        <div style={{ width: 266, fontSize: 14 }} dir="rtl">
          {this.props.text}
        </div>
      </div>
    );
  }
}
