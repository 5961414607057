import React from "react";

const FacebookButton = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: props.top,
        left: 2,
        width: 23,
        height: 23,
        fontSize: 12,
      }}
      onClick={props.onClick}
    >
      <img
        name="fb"
        alt="facebook button"
        src={require("../images/icon-facebook.svg")}
        style={{ width: 23, height: 23 }}
      ></img>
    </div>
  );
};

export default FacebookButton;
