import React from "react";

export default class MModalSpecial extends React.Component {
  constructor(props) {
    super(props);
    console.log("MModalSpecial constructor");
    this.state = { isOpen: true, day_txt: null, people: 10 };
    this.visible = this.props.visible;
  }
  onClose() {
    if (this.props.onClose != null) {
      this.props.onClose();
    }
  }
  onClick() {
    console.log("onClick");
  }

  getPeople(v) {
    var p = this.state.people;
    if (v != null) {
      p = p + v;
      if (p < 0) {
        p = 0;
      }
    }
    if (p < 10) {
      return "0" + p;
    }
    return p;
  }
  onPeopleDown() {
    if (this.state.people > 1) {
      this.setState({ people: this.state.people - 1 });
    }
  }
  onPeopleUp() {
    if (this.state.people < 11) {
      this.setState({ people: this.state.people + 1 });
    }
  }

  render() {
    //console.log("MMOdalSpecial render", this.props)

    var display = "none";
    if (this.props.visible == true) {
      display = "block";
    }

    let _height = 200;

    if (this.props.textTitle) {
      _height = 100 + (this.props.textTitle.length / 30) * 35 + 35;
    }

    return (
      <div id="myModal" className="mmodal" style={{ display: display }}>
        {this.props.imageToShow != null ? (
          <div
            className="mmodal-confirm-content"
            style={{
              borderRadius: 20,
              //minHeight: 156,
              width: "auto",
              minHeight : 150,
              maxWidth: 350,
              maxHeight: 500,
              position: "relative",
              marginTop: 50,
            }}
          >
            <div
              onClick={this.onClose.bind(this)}
              className="close"
              style={{
                position: "absolute",
                top: 15,
                right: 20,
                float: "right",
                color: "black",
                fontSize: 28,
                backgroundColor: "white",
                padding: 15,
                borderRadius: 25,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              X
            </div>
            <img
              src={this.props.imageToShow}
              style={{ borderRadius: 20, maxWidth: "100%", maxHeight: "100%" }}
              alt="web_img"
            />
          </div>
        ) : (
          <div
            className="mmodal-confirm-content"
            style={{
              borderRadius: 20,
              minHeight: 156,
              position: "relative",
              height: _height,
              display : 'flex',
              justifyContent : 'center',
              alignItems : 'center',
              flexDirection : 'column'
              // backgroundColor : 'red'
            }}
          >
            
            <div style={{
              display : 'flex'
            }}>
              
              <div
                style={{
                  fontFamily: "almoni_neue_dl_4.0_aaabold",
                  fontWeight: "bold",
                  fontSize: 23,
                  color: "#101010",
                  // position: "absolute",
                  // top: 79,
                  // left: 0,
                  width: "100%",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                {this.props.textTitle}
              </div>
            </div>
            <div
              onClick={this.onClose.bind(this)}
              className="close"

              style={{
                display : 'flex',
                // alignTexmarginRight: 17,
                // marginTop: 12,
                color: "black",
                fontSize: 28,
                // position : 'absolute',
                bottom : 0,
                alignSelf : 'center'
              }}
            >
              x
            </div>
            {/* <div style={{ fontFamily: 'almoni_neue_dl_4.0_aaabold', fontSize: 13, color: '#101010', position: 'absolute', top: 115, left: 0,width: '100%' }}>
                            test
                        </div> */}
          </div>
        )}
      </div>
    );
  }
}
