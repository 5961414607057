import React from 'react'
import Config from "../Config";

const ClubMemberButton = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 191,
        left: 2,
        width: 101,
        height: 28,
        fontSize: 12,
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 1,
        borderRadius: 11,
        borderStyle: "solid",
        verticalAlign: "middle",
        display: "table-cell",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundColor: "black",
          width: 25,
          height: 26,
          float: "left",
          borderTopLeftRadius: 9,
          borderBottomLeftRadius: 9,
        }}
      >
        <img
          style={{ marginTop: 5 }}
          src={Config.apiEndPoint + "/images/icons/crown.svg"}
        ></img>
      </div>
      <div style={{ marginTop: 10, marginTop: 5, fontSize: 10 }}>
        {" "}
        {props.text}{" "}
      </div>
    </div>
  );
};
export default ClubMemberButton